import React, { Component, Fragment } from 'react'
import {Helmet} from 'react-helmet'

export class CaraKerja extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Cara Kerja - {process.env.REACT_APP_TITLE}</title>
                    <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
                    <meta name="keywords" content={process.env.REACT_APP_KEYWORD} />
                </Helmet>

                <h1 className='judul-page'>Cara Kerja</h1>

                <div className='content'>
                    <p>Kontribusi bersama <b>TemanBaik</b></p>

                    <p>Dengan metode crowdfunding, memungkinkan kontributor untuk berkontribusi membantu penerima dana melalui platform TemanBaik. Penerima dana bisa mendapatkan dana yang dibutuhkan untuk menjalankan usahanya. Sementara, kontributor dapat memberikan bantuan kepada penerima dana dan akan mendapatkan laporan secara berkala terkait penggunaan dana yang sudah diberikan. </p>
                </div>
            </Fragment>
        )
    }
}

export default CaraKerja
