import React, { Component, Fragment } from 'react'
import {Helmet} from 'react-helmet'

export class Faq extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>FAQ - {process.env.REACT_APP_TITLE}</title>
                    <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
                    <meta name="keywords" content={process.env.REACT_APP_KEYWORD} />
                </Helmet>

                <h1 className='judul-page'>FAQ TemanBaik</h1>

                <div className='content'>
                    <h2 className='judul-sub'>Kontributor</h2>
                    <ul className='faq-content'>
                        <li>Q: Apa itu TemanBaik?</li>
                        <li>A: TemanBaik merupakan sebuah gerakan galang dana untuk berkontribusi membantu usaha atau bisnis mikro yang saat ini terkena dampak persebaran virus corona.</li>
                        <li>Q: Bagaimana cara saya berkontribusi?</li>
                        <li>A: Anda dapat lakukan transfer ke rekening BCA di rekening 6390111175 atau BNI di rekening 0902645446. sesuai jumlah dana yang Anda inginkan. Setelah itu, Anda wajib mengunggah foto bukti transfer tersebut ke kolom yang telah disediakan.</li>
                        <li>Q: Bagaimana cara saya mengetahui bahwa penerima dana benar-benar membutuhkan dana?</li>
                        <li>A: Tim kami akan melakukan proses review dan verifikasi. Jika kelengkapan data sudah sesuai maka campaign dari penerima dana akan muncul.</li>
                        <li>Q: Bagaimana saya mengetahui berapa total uang yang diterima oleh penerima dana?</li>
                        <li>A: Kami akan menyediakan laporan terkait dana yang telah berhasil dikumpulkan dan detail dana yang diterima oleh penerima dana.</li>
                        <li>Q: Apakah saya akan mendapatkan laporan terkait dana yang saya donasikan?</li>
                        <li>A: Ya. Kami akan melakukan laporan secara berkala untuk menjelaskan bagaimana donasi Anda sudah digunakan oleh penerima dana.</li>
                    </ul>
                </div>

                <div className='content'>
                    <h2 className='judul-sub'>Penerima Dana</h2>
                    <ul className='faq-content'>
                        <li>Q: Apa itu campaign?</li>
                        <li>A: Campign adalah proses penerima dana untuk mendapatkan dana dari para kontributor yang ingin berkontribusi.</li>
                        <li>Q: Bagaimana cara saya membuat campaign?</li>
                        <li>A: Untuk membuat campign, Anda tinggal mengisi Google Form New Campaign. Lengkapi semua kolom yang disediakan.</li>
                        <li>Q: Apakah saya sudah pasti mendapatkan donasi?</li>
                        <li>A: Kami tidak menjamin bahwa Anda bisa mendapatkan donasi sesuai dengan keinginan Anda. Hal itu sepenuhnya kami serahkan kepada donatur yang akan memberikan donasi. Kami hanya memberikan fasilitas untuk mempertemukan penerima dana dengan donatur. Selain itu, kami juga akan terus membantu promosi Anda sehingga peluang untuk mendapatkan donasi lebih besar.</li>
                        <li>Q: Apakah saya langsung masuk ke daftar penerima dana setelah mengisi Form New Campaign?</li>
                        <li>A: Tim kami akan melakukan verifikasi data minimal 1x24 jam hari kerja terlebih dahulu. Jika data tidak sesuai, maka pengajuan otomatis ditolak dan Anda akan mendapatkan notifikasi terkait penolakan tersebut. Sebaliknya jika data Anda telah lengkah dan sesuai, campaign yang Anda ajukan langsung masuk ke data campaign kami.</li>
                        <li>Q: Apa yang harus saya lakukan setelah menerima dana?</li>
                        <li>A: Setelah menerima dana, yang harus Anda lakukan ialah membuat laporan secara berkala berupa foto atau video yang nantinya akan digunakan oleh tim kami sebagai laporan kepada donatur.</li>
                        {/* <li>Q: Apakah saya akan dikenakan biaya terkait pengajuan campaign?</li>
                        <li>A: Kami mengenakan biaya transaksi sebesar …..</li> */}
                    </ul>
                </div>
            </Fragment>
        )
    }
}

export default Faq
