import React, { Component, Fragment } from 'react'

export class Kontributor extends Component {
    render() {
        return (
            <Fragment>
                <h1 className='judul-page'>Kontributor</h1>

                <div className='content'>
                    <p>Kontributor</p>
                </div>
            </Fragment>
        )
    }
}

export default Kontributor
