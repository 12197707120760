import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { GetCampaign } from '../../../redux/actions/GetCampaign'
import ListCampaign from '../../organism/ListCampaign';
import {Helmet} from 'react-helmet';

export class Campaign extends Component {
    componentDidMount = () =>{
        this.props.GetCampaign();
    }

    render() {
        const {statusState, campaignState} = this.props;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Campaign - {process.env.REACT_APP_TITLE}</title>
                    <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
                    <meta name="keywords" content={process.env.REACT_APP_KEYWORD} />
                </Helmet>

                <h1 className='judul-page'>Campaign</h1>

                <div className='content'>
                    {   
                        statusState.loading ? 
                        <div className='bg-loading-campaign'>
                            <div className='spinner spinner-border' />
                        </div> : campaignState.list && <ListCampaign {...this.props} />
                    }

                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
	return {
        campaignState : state.campaignState,
        statusState : state.statusState	
    }
}

const mapDispatchToProps = dispatch =>{
	return {
		GetCampaign : () => {
			dispatch(GetCampaign())
        }
	}
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Campaign)
