import {SET_ERROR_STATE, REQUEST_STATE, REQUEST_DONE, SET_BANK_LIST } from '../../utils/constants/actionTypes';
const BASE_URL = process.env.REACT_APP_BASE_URL;
    
export const GetBanks = () => async (dispatch) => {
    dispatch({ type: REQUEST_STATE })
    try {
        const options = { 
            method : 'GET',
            credentials: 'include'
        }
        
        const res = await fetch(`${BASE_URL}/campaign/teman-baik/fundings/banks`, options);
        const hasil = await res.json();

        if(res.status === 200) {
            dispatch({type : REQUEST_DONE})
            dispatch({type : SET_BANK_LIST, payload : hasil.result.campaign.banks})
        }else{
            dispatch({ type: SET_ERROR_STATE, payload : hasil.status.message })
        }
    }catch(err){
        dispatch({ type: SET_ERROR_STATE, payload : err.message })
    }
}