import { combineReducers } from 'redux';

//For dispatch state
import statusState from './statusState'
import campaignState from './campaignState'
import bankState from './bankState'
 

const rootReducer = combineReducers({
	statusState, campaignState, bankState
})

export default rootReducer;