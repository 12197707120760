import React, {Component} from 'react';
import Button from '../../../components/atoms/Button';



class PageNotFound extends Component {
	constructor(props){
		super(props);
		this.state = {

		}
	}

	onClickHandler = props =>  this.props.history.replace('/');

	render() {
		return (
			<div className='wrapper-notFound d-flex align-items-start align-items-md-center justify-content-center '>
				<div className="container d-flex flex-column-reverse flex-md-row align-items-center justify-content-around">
					<img className='d-none d-md-block' src="/img/404.png" alt="" />
					
					<div className="text404 d-flex flex-column align-items-center align-items-md-start mt-md-0 mb-md-0 mb-5 mt-5">
						<h1>404</h1>
						<p>Oops, halaman yang Anda cari tidak tersedia</p>
						<Button type='button' title='Kembali ke Beranda' onClick={this.onClickHandler} className='mt-2' color='yellow' width='big-rounded'/>
					</div>
				</div>
			</div>
		)
	}
}

export default PageNotFound;