export const logo = '/img/logo.png'
export const logo_klikcair = '/img/logo-klikcair.png'
export const img_temanbaik = '/img/temanBaik.jpeg'

export const bca = '/img/bank/bca.png'
export const bni = '/img/bank/bni.png'
export const bersama = '/img/bank/bersama.png'


export const partner_klikcair = '/img/partner/KlikCair.jpg'
export const partner_koperasi = '/img/partner/Koperasi.jpg'
export const partner_bankmaju = '/img/partner/BankMaju.jpg'
export const partner_kliknikgo = '/img/partner/KlinikGo.jpg'
export const partner_perawatku = '/img/partner/Perawatku.jpg'