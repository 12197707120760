import React from 'react';
import Label from '../atoms/Label';

export default ({ label, note, info, labelregular, fullwidth, col, children, required }) => {
	let colLabel = 'col-md-6';
	let colText = 'col-md-6';
	if(col) {
		colLabel = col.label
		colText = col.text
	}

	return (
		<div className='labelWithInput-Wrapper'>
			<div className='row align-items-start'>
				<div className={`${colLabel} text-left`}>
					{ labelregular ? <Label value={label} className={`color-text-label label-kc-s`} /> : <Label value={label} className='color-text-label label-kc-sb' /> }
					{required && <sup className='required'>*</sup>}
				</div>

				<div className={`${colText} text-left`}>
					<div className={`form-group ${fullwidth ? 'full-width':''}`}>
						{children}
					</div>
				</div>
			</div>

			{
				(note || info) && (
					<div className='row bg-note'>
						<div className={`${colLabel} text-left`}> &nbsp; </div>
						<div className={`${colText} text-left d-flex  flex-column`}>
							{ info && <Label value={info} type='info' className='mt-1' /> }
							{ note && <Label value={note} type='info' className='mt-1 red' /> }
						</div>
					</div>
				)
			}
		</div>
	)
}