// State for sistem akses transaction
export const REQUEST_STATE = 'REQUEST_STATE';
export const REQUEST_PAGE_STATE = 'REQUEST_PAGE_STATE';
export const REQUEST_DONE = 'REQUEST_DONE';
export const SET_ERROR_STATE = 'SET_ERROR_STATE';
export const SET_SUCCESS_STATE = 'SET_SUCCESS_STATE';
export const RESET_STATUS_STATE = 'RESET_STATUS_STATE';

export const SET_LIST_CAMPAIGN = 'SET_LIST_CAMPAIGN';
export const SET_DETAIL_CAMPAIGN = 'SET_DETAIL_CAMPAIGN';
export const RESET_CAMPAIGN = 'RESET_CAMPAIGN';

export const SET_BANK_LIST = 'SET_BANK_LIST';