import React from 'react'
import Label from '../atoms/Label';

export default ({name, exetension, onChange, loading, files}) => {

    return (
        <div className='d-flex justify-content-start align-items-center'>
            <div className='form-content mr-2'>
                <label className="btn-kc btn-kc-img btn-kc-yellow m-0" htmlFor={`upload-${name}`} style={{ cursor: 'pointer' }}>
                    <img alt='Upload' src='/img/icon/icon-upload.png' /> 
                </label>

                <input type="file" name={name} id={`upload-${name}`} accept={exetension || '.png,.jpg,.jpeg'} style={{ display: "none" }} onChange={(file) => {onChange(name, file.target.files[0]) }} />
            </div>

            {
                (loading) ? (
                    <div className='d-flex align-items-center'>
                        <span className='spinner-border spinner-border-sm text-secondary'></span>
                        <Label className='ml-1' value='Mengunggah...' />
                    </div> 
                ) : files && (
                    files === 'selected' ? <Label className='blue' value='1 File Terpilih' /> : 
                    <a href={files} target="_blank" rel="noopener noreferrer"><label className='label-kc blue link'>Lihat Data</label></a>
                )
             }
        </div>
    )
}