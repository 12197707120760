import React, { Component, Fragment } from 'react'
import {Helmet} from 'react-helmet'
import { partner_klikcair, partner_bankmaju, partner_koperasi, partner_kliknikgo, partner_perawatku } from '../../../utils/constants/imagePath'

export class TentangKami extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Tentang Kami - {process.env.REACT_APP_TITLE}</title>
                    <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
                    <meta name="keywords" content={process.env.REACT_APP_KEYWORD} />
                </Helmet>

                <h1 className='judul-page'>Tentang Kami</h1>

                <div className='content'>
                    <p>TemanBaik merupakan sebuah gerakan galang dana yang diinisiasi oleh KlikCair dalam rangka membantu usaha atau bisnis mikro yang terdampak oleh persebaran virus corona atau COVID-19. </p>
                    <p>Melalui TemanBaik, kontributor bisa berkontribusi untuk membantu memberikan bantuan sukarela kepada pengusaha yang membutuhkan.</p>
                </div>

                <div className='content partner-kami'>
                    <h2 className='judul-sub'>Partner Kami</h2>
                    <div className='row'>
                        <div className='col-4'>
                            <img src={partner_klikcair} alt="klikcair" />
                        </div>
                        <div className='col-4'>
                            <img src={partner_koperasi} alt="Keperasi Maju" />
                        </div>
                        <div className='col-4'>
                            <img src={partner_bankmaju} alt="Bank Maju" />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-3'>
                            <img src={partner_kliknikgo} alt="Klinik GO" />
                        </div>
                        <div className='col-3'>
                            <img src={partner_perawatku} alt="Perawatku" />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default TentangKami
