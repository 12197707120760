import React, { Component } from 'react'
import formatNumber from '../../utils/halpers/formatNumber'
import getCountDownDueDate from '../../utils/halpers/getCountDownDueDate'

export class ListCampaign extends Component {
    goToDetail = (id) => {
        this.props.history.push(`campaign/${id}`)
    }

    render() {
        const {campaignState} = this.props;
        
        return (
            <div className='campaign'>
                {campaignState.list.map((data, index) => {
                    const percentage = ((data.campaignFundedAmount / data.campaignAmount) * 100).toFixed(0);

                    return (
                        <div key={index} className='list-campaign' onClick={() => {this.goToDetail(data.campaignCode)}}>
                            <h2>{data.campaignTitle}</h2>

                            <span className='nama-umkm'>{data.businessName}</span>
                            <span>{data.businessType}</span>

                            <div className={`mt-3 mb-3 progress`} style={{height : '15px'}}>
                                <div className='progress-bar' role="progressbar" style={{width : `${percentage}%`}}  aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">{percentage}%</div>
                            </div>

                            <div className='d-flex justify-content-between align-items-start'>
                                <div className='text-left'>
                                    <span>Terkumpul</span>
                                    <span><b>{formatNumber(data.campaignFundedAmount)}</b></span>
                                </div>

                                <div className='text-right'>
                                    <span>Sisa Hari</span>
                                    <span><b>{getCountDownDueDate(data.campaignEndDate)} Hari</b></span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default ListCampaign