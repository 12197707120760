import React from 'react'

export default (props) => {
    const {value, type, className} = props;
    let result = null;
    
    const classes = [...[className], 'label-kc'].join(' ');

  
    switch(type) {
        case ('title') : 
            result = <h1 className={classes} onClick={props.onClick}> {value} </h1>;
            break;
        case ('subtitle') : 
            result = <h2 className={classes} onClick={props.onClick}> {value} </h2>;
            break;
        case ('paragraph') :
            result = <p className={classes} onClick={props.onClick}> {value} </p>;
            break;
        case ('info') :
            result = <span className={classes} onClick={props.onClick}> {value} </span>;
            break;
        default :
            result = <label htmlFor={(props.for) ? props.for : ''} className={classes} onClick={props.onClick}> {value} </label>
            break;
    }

    return result;
}