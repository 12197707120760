import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import history from './history';
import Template from './components/template';

//Pages
import Home from './containers/pages/Beranda';
import PageNotFound from './containers/pages/PageNotFound';
import TentangKami from './containers/pages/TentangKami';
import Faq from './containers/pages/Faq';
import CaraKerja from './containers/pages/CaraKerja';
import Kontributor from './containers/pages/Kontributor';
import Campaign from './containers/pages/Campaign';
import CampaignDetail from './containers/pages/CampaignDetail';
import Pendanaan from './containers/pages/Pendanaan';

const PageNotFoundRoute = (props) => (
    <Redirect to="/404"></Redirect>
)
const Router = () => (
	<Switch>
		<Route path="/" component={wrappedRoutes}/>
	</Switch>
);

const wrappedRoutes = (props) => (
	<Template {...props} history={history} >	
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/beranda" component={Home} />
			<Route exact path="/tentang-kami" component={TentangKami} />
			<Route exact path="/faq" component={Faq} />
			<Route exact path="/cara-kerja" component={CaraKerja} />
			<Route exact path="/kontributor" component={Kontributor} />
			<Route exact path="/campaign" component={Campaign} /> 
			<Route exact path="/campaign/:idcamp" component={CampaignDetail} /> 
			<Route exact path="/pendanaan/:idcamp" component={Pendanaan} /> 
			<Route exact path="/404" component={PageNotFound} />
			<Route component={PageNotFoundRoute} />
		</Switch>
	</Template>
);

export default Router;