import React, { Component } from 'react'


export default class TextBox extends Component {
    constructor (props) {
        super(props);

        this.state = {
            viewPass : false
        }
    }    

    toogleViewPass = (name) => {
        const {viewPass} = this.state;

        !viewPass ? this.setState({viewPass : true}) : this.setState({viewPass : false});
    }

    render () {
        const {type, name, placeholder, value, onChange, isDisabled, isError, opts} = this.props;

        return (
            <div className='input-kc-wrapper'>
                <input 
                    {...opts}
                    name={name} 
                    placeholder={placeholder} 
                    value={value} 
                    disabled={isDisabled}
                    type={!this.state.viewPass ? type : 'text'} 
                    onChange={onChange}
                    className={`form-control textbox-kc${(isError) ? ' textbox-kc-error' : ''} ${this.props.className}`}
                />


                { (type === 'password')  && <span onClick={() => { this.toogleViewPass(name) }} className={`iconViewPass fa ${!this.state.viewPass ? 'fa-eye' : 'fa-eye-slash'}`}></span> }
            </div>
        )
    }
}

TextBox.defaultProps = {
    isError : false,
    isDisabled : false
}