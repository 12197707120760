import  {SET_LIST_CAMPAIGN, SET_DETAIL_CAMPAIGN, RESET_CAMPAIGN} from '../../utils/constants/actionTypes';

const initialState= {
	list : null,
	detail : null
}

const campaignState = (state = initialState, action) =>{
	switch(action.type){
		case RESET_CAMPAIGN: 
			return {
                list : null,
                detail : null
            }
        case SET_LIST_CAMPAIGN : 
            return {
                list : action.payload
            }
        case SET_DETAIL_CAMPAIGN :
            return {
                detail : action.payload
            }
		default:
			return state
	}
}


export default campaignState;
