import React, { Component } from 'react'
import formatNumber from '../../utils/halpers/formatNumber';
import getCountDownDueDate from '../../utils/halpers/getCountDownDueDate';
import Button from '../../components/atoms/Button';

import {
    FacebookShareButton, FacebookIcon,
    LineShareButton, LineIcon,
    LinkedinShareButton, LinkedinIcon,
    TelegramShareButton, TelegramIcon,
    TwitterShareButton, TwitterIcon,
    WhatsappShareButton, WhatsappIcon
  } from "react-share";

export class DetailCampaign extends Component {
    render() {
        const {detail} = this.props.campaignState;
        const percentage = ((detail.campaignFundedAmount / detail.campaignAmount) * 100).toFixed(0);
        const shareLink = `${process.env.REACT_APP_FRONTEND_URL}/temanbaik/campaign/${window.btoa(detail.campaignCode)}/${window.btoa(process.env.REACT_APP_BASE_URL)}/${window.btoa(window.location.href)}`;
        const propsIcon = {size : 32, borderRadius : 3}
        // console.log("detail campaign === ", detail.campaignStory);
        
        return (
            <div className='campaign'>
                <div className='img-responsive'>
                    <img src={detail.file} alt='Detail Campaign' />
                </div>
                
                <h2 className='mt-3'>{detail.campaignTitle}</h2>
                <span>
                    <b>{formatNumber(detail.campaignFundedAmount)}</b> Terkumpul dari {formatNumber(detail.campaignAmount)}
                </span>

                <div className={`mt-3 mb-3 progress`} style={{height : '15px'}}>
                    <div className='progress-bar' role="progressbar" style={{width : `${percentage}%`}}  aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">{percentage}%</div>
                </div>

                <div className='d-flex justify-content-between align-items-start'>
                    <span>{detail.noOfDonations} Donasi</span>
                    <span>{getCountDownDueDate(detail.campaignEndDate)} Hari Lagi</span>
                </div>

                <h3 className='mt-4 judul-sub'>Cerita</h3>
                <p style={{whiteSpace: 'pre-line'}}>{detail.campaignStory}</p>
                
                <Button onClick={() => {this.props.history.push(`/pendanaan/${detail.campaignCode}`)}} width='block' color='yellow' title='Dukung Sekarang' />
                
                <div className='shareCampaign'>
                    <span>Bagikan campaign ini melalui :</span>
                    
                    <div className='d-flex flex-wrap mt-2'>
                        <WhatsappShareButton url={shareLink}>
                            <WhatsappIcon {...propsIcon} />
                        </WhatsappShareButton>

                        <TelegramShareButton url={shareLink}>
                            <TelegramIcon {...propsIcon} />
                        </TelegramShareButton>

                        <LineShareButton url={shareLink}>
                            <LineIcon {...propsIcon} />
                        </LineShareButton>

                        <FacebookShareButton url={shareLink}>
                            <FacebookIcon {...propsIcon} />
                        </FacebookShareButton>

                        <TwitterShareButton url={shareLink}>
                            <TwitterIcon {...propsIcon} />
                        </TwitterShareButton>

                        <LinkedinShareButton url={shareLink}>
                            <LinkedinIcon {...propsIcon} />
                        </LinkedinShareButton>
                    </div>
                </div>
            </div>
        )
    }
}


export default DetailCampaign;
