import React, { Component, Fragment} from 'react'
import Button from '../../../components/atoms/Button'
import {Helmet} from 'react-helmet'
import {img_temanbaik} from '../../../utils/constants/imagePath'

export class Home extends Component {
    goToCampaign = () => {
        this.props.history.push('campaign')
    }


    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Beranda - {process.env.REACT_APP_TITLE}</title>
                    <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
                    <meta name="keywords" content={process.env.REACT_APP_KEYWORD} />
                </Helmet>

                {/* <h1 className='judul-page'>Beranda</h1> */}

                <div className='content'>
                    <div className={'container-img-temanbaik'}>
                        <img src={img_temanbaik} alt='img-temanbaik' className={'img-temanbaik'} />
                    </div>

                    <p>Saat ini kita sudah memasuki masa new normal atau tatanan baru yang diterapkan selama pandemi COVID-19. Hal itu menjadikan kita harus mempersiapkan diri dan tetap menerapkan protokol kesehatan seperti yang sudah dianjurkan oleh pemerintah. Namun, masih banyak lapisan masyarakat terutama pengusaha UMKM terdampak pandemi COVID-19 yang masih membutuhkan uluran tangan kita. </p>
                    <p>Atas dasar itu, KlikCair menginisiasi sebuah gerakan sosial bernama TemanBaik. TemanBaik merupakan sebuah gerakan galang dana untuk berkontribusi membantu UMKM yang terdampak pandemi COVID-19.</p>
                    <p>Kami mengajak kalian untuk mengulurkan tangan kepada para pelaku UMKM yang sedang kesulitan dalam menghadapi pandemi COVID-19 ini. Mari bersama bergandeng tangan melewati masa-masa sulit ini.</p>
                    
                    {/* <p>Dengan berlakunya kebijakan PSBB untuk mencegah penyebaran pandemi COVID-19, otomatis berdampak pada perekonomian masyarakat ibu kota akhir-akhir ini. Banyak usaha yang mengalami kerugian bahkan tidak sedikit juga yang gulung tikar.</p>
                    <p>Atas dasar itu, KlikCair menginisiasi sebuah gerakan sosial bernama TemanBaik. TemanBaik merupakan sebuah gerakan galang dana untuk berkontribusi membantu UMKM yang saat ini terkena dampak pandemi COVID-19. </p>
                    <p>Kami mengajak kalian para teman baik untuk menjadi teman bagi para pelaku UMKM yang sedang kesulitan menghadapi pandemi COVID-19 ini. Mari teman baik, bersama bergandengan tangan melewati masa-masa sulit ini.</p>
                    */}
                   
                    {/* <p>Sejak Jumat, 10 April 2020, Pemerintah DKI Jakarta sudah menerapkan kebijakan pembatasan sosial berskala besar (PSBB). Kebijakan tersebut dilakukan untuk mencegah penyebaran pandemi corona virus atau COVID-19.</p>
                    <p>Dengan berlakunya kebijakan PSBB, otomatis berdampak pada perekonomian masyarakat ibu kota akhir-akhir ini. Banyak usaha yang mengalami kerugian bahkan tidak sedikit juga yang memutuskan untuk gulung tikar.</p>
                    <p>Atas dasar itu, KlikCair menginisiasi sebuah gerakan sosial bernama TemanBaik. TemanBaik merupakan sebuah gerakan galang dana untuk berkontribusi membantu usaha atau bisnis mikro yang saat ini terkena dampak persebaran virus corona. Untuk saat ini, TemanBaik masih fokus di wilayah ibu kota DKI Jakarta.</p>
                    <p>Kami mengajak kalian para teman baik untuk menjadi teman bagi para pelaku usaha / bisnis mikro yang sedang kesulitan menghadapi pandemi COVID-19 ini. Mari teman baik, bersama bergandengan tangan melewati masa-masa sulit ini.</p> */}
                </div>


                <div className='d-flex justify-content-center align-items-center pb-5'>
                    <Button onClick={this.goToCampaign} width='big' color='green' title='Lihat Campaign' />
                </div>
            </Fragment>
        )
    }
}

export default Home;
