import React from 'react'

export default ({data, name, onChange, className}) => {
    return (
        <div className={`d-flex ${className}`}>
            {
                data.map(({value, text, checked}, key) => {
                    return (
                        <label key={key} className="checkbox-inline label-kc d-flex align-items-start justify-content-start mr-3">
                            <input type="checkbox" onChange={e => onChange(e.target)} style={{marginTop : '3px'}} className='mr-2 ml-0' value={value} name={name} checked={checked || false} /> 
                            <div className='text-justify' dangerouslySetInnerHTML={{__html: text}} />
                        </label>
                    )
                })
            }
        </div>
    )
}
