import React, { Fragment } from 'react'

export default (props) => {
    const {title, type, onClick, className, loading, color, width, isDisabled, url, children} = props;
    let classes = [...[className], width, 'btn-kc'];
    if (loading || isDisabled) {
        classes = [...classes, 'disable'];
    }else{
        classes = [...classes, color]
    }


    const onClickDisable = (e) => {
        e.preventDefault();
        return false;
    }
    let btn =  (
            <button type={type} onClick={(loading || isDisabled) ? onClickDisable : onClick} className={classes.join(' ')}>
                {
                    (!loading) ? children ? children : title :
                    <div className="spinner-border text-secondary loader" role="status"> </div>
                }
            </button>
    )
    return (
        <Fragment>
            { url ?  <a href={url} rel="noopener noreferrer" target="_blank"> {btn} </a> : btn }
        </Fragment>
    )
}
