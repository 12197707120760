import {SET_LIST_CAMPAIGN, SET_DETAIL_CAMPAIGN, SET_ERROR_STATE, REQUEST_STATE, REQUEST_DONE } from '../../utils/constants/actionTypes';
const BASE_URL = process.env.REACT_APP_BASE_URL;
    
export const GetCampaign = (status = 'PBD') => async (dispatch) => {
    dispatch({ type: REQUEST_STATE })
    try {
        const options = { 
            method : 'GET',
            credentials: 'include'
        }
        
        const res = await fetch(`${BASE_URL}/campaign/teman-baik/lists?status=${status}`, options);
        const hasil = await res.json();

        if(res.status === 200) {
            dispatch({type : REQUEST_DONE})
            dispatch({type : SET_LIST_CAMPAIGN, payload : hasil.result.campaigns})
        }else{
            dispatch({ type: SET_ERROR_STATE, payload : hasil.status.message })
        }
    }catch(err){
        dispatch({ type: SET_ERROR_STATE, payload : err.message })
    }
}

export const GetCampaignDetail = (id) => async (dispatch) => {
    dispatch({ type: REQUEST_STATE })
    try {
        const options = { 
            method : 'GET',
            credentials: 'include'
        }
        
        const res = await fetch(`${BASE_URL}/campaign/teman-baik/${id}`, options);
        const hasil = await res.json();
        const resDoc = await fetch(`${BASE_URL}/document/campaign/teman-baik/${hasil.result.campaign.campaignBannerFileUID}`, options);
        const hasilDoc = await resDoc.json();

        if(res.status === 200 && resDoc.status === 200) {
            const hasilReturn = {
                ...hasil.result.campaign,
                file : hasilDoc.result.document.campaign.url
            }
            dispatch({type : REQUEST_DONE})
            dispatch({type : SET_DETAIL_CAMPAIGN, payload :hasilReturn });
        }else{
            dispatch({ type: SET_ERROR_STATE, payload : hasil.status.message });
        }
    }catch(err){
        dispatch({ type: SET_ERROR_STATE, payload : err.message });
    }
}
