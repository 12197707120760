export default (duedate) => {
    var date = new Date(duedate)
    var today = new Date()
    today.setHours(0, 0, 0, 0)
    // console.log(today)
    var oneDay = 24 * 60 * 60 * 1000
    var firstDate = today
    var secondDate = date
    var sisaHari = Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay));
    return sisaHari
}
