import React, { Component, Fragment } from 'react'
import { logo_klikcair } from '../../utils/constants/imagePath'

export default class KcFooter extends Component {
    render() {
        return (
            <Fragment>
                <div className='hr-footer' />
                <footer className="main-footer">
                    <span>&copy; {new Date().getFullYear()} TemanBaik by</span>

                    <a href='https://klikcair.com/' target="_blank" rel="noopener noreferrer" >
                        <img src={logo_klikcair} alt='logo-klikcair' />
                    </a>
                </footer>
            </Fragment>
        )
    }
}
