import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import './assets/style/index.scss';
import Router from './Router';

/** Main app component */
class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		);
	}
}

export default connect(null, null)(App);