import React, { Component } from 'react'
import KcHeader from './KcHeader'
import KcFooter from './KcFooter'
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

export class Template extends Component {
    render() {
		const children = this.props.children || null;
        return (
            <div className='page-wrapper'>
                <KcHeader />
                <div className='wrapper'>
                    {children}
                </div>
                <KcFooter />

                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} timer="10000" />
            </div>
        )
    }
}


export default Template;
