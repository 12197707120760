import {SET_ERROR_STATE, SET_SUCCESS_STATE } from '../../utils/constants/actionTypes';
const BASE_URL = process.env.REACT_APP_BASE_URL;
    

export default (id, data, cb) => async (dispatch) => {
    // console.log(data.get('parameters'))
	try {
        let res = await fetch(`${BASE_URL}${`/campaign/teman-baik/${id}/fund`}`, {
            method : 'POST',
            credentials: 'include',
            body : data
        });
        
        let hasil = await res.json();
        
		if (res.status === 200) {
            dispatch({type : SET_SUCCESS_STATE, payload : hasil.status.message})
			cb(true)
		} else {
            cb(false)
            dispatch({type : SET_ERROR_STATE, payload : hasil.status.message})
		}
	} catch (err) {
        cb(false)
        dispatch({type : SET_ERROR_STATE, payload : err.message})
	}
}