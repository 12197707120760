import  {REQUEST_STATE, SET_ERROR_STATE, SET_SUCCESS_STATE, RESET_STATUS_STATE, REQUEST_DONE, REQUEST_PAGE_STATE} from '../../utils/constants/actionTypes';
import {ToastsStore} from 'react-toasts';

const initialState= {
	error: null,
	statusError : false,
	success: false,
	successMsg : null,
	login : null,
	loading : false,
	loadingPage : false
}

const statusState = (state = initialState, action) =>{
	switch(action.type){
		case REQUEST_STATE:
			return{
				loading : true
			}
		case REQUEST_PAGE_STATE:
			return{
				loadingPage : true
			}
		case REQUEST_DONE:
			return{
				loading : false,
				loadingPage : false
			}
		case SET_ERROR_STATE: 
			ToastsStore.error(action.payload);
			return {
				error: action.payload,
				statusError : true,
				success: false,
				successMsg : null,
				loading : false,
				loadingPage : false
			}
		case SET_SUCCESS_STATE: 
			ToastsStore.success(action.payload);
			return {
				error: null,
				statusError : false,
				success: true,
				successMsg : action.payload,
				loading : false,
				loadingPage : false
			}  
		case RESET_STATUS_STATE: 
			return {
				error: null,
				statusError : false,
				success: false,
				successMsg : null,
				loading : false,
				loadingPage : false
			}
		default:
			return state
	}
}


export default statusState;
