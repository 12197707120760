import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {  GetCampaignDetail } from '../../../redux/actions/GetCampaign'
import DetailCampaign from '../../organism/DetailCampaign'
import {Helmet} from 'react-helmet'

export class CampaignDetail extends Component {
    componentDidMount = () =>{
        const {match, GetCampaignDetail, campaignState } = this.props;

        if(!campaignState.detail){
            GetCampaignDetail(match.params.idcamp);
        }
    }

    render() {
        const {statusState, campaignState} = this.props;
        return (
            <Fragment>

                {campaignState.detail &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{campaignState.detail.campaignTitle} - Campaign - {process.env.REACT_APP_TITLE}</title>
                        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
                        <meta name="keywords" content={process.env.REACT_APP_KEYWORD} />

                        <meta property="og:title" content={`${campaignState.detail.campaignTitle} - Campaign - ${process.env.REACT_APP_TITLE}`} />
                        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
                        <meta property="og:image" content={campaignState.detail.file} />
                        <meta property="og:url" content={window.location.href} />
                    </Helmet>
                }

                <h1 className='judul-page'>Detail Campaign</h1>

                <div className='content'>
                    {   
                        statusState.loading ? 
                        <div className='bg-loading-campaign'>
                            <div className='spinner spinner-border' />
                        </div> : campaignState.detail && <DetailCampaign {...this.props} />
                    }

                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
	return {
        campaignState : state.campaignState,
        statusState : state.statusState	
    }
}

const mapDispatchToProps = dispatch =>{
	return {
        GetCampaignDetail : (id) => {
            dispatch(GetCampaignDetail(id))
        }
	}
}
  

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetail)
