import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import LabelWithInput from '../../../components/molecules/LabelWithInput';
import Label from '../../../components/atoms/Label';
import TextBox from '../../../components/atoms/TextBox';
import CheckBox from '../../../components/atoms/CheckBox';
import Button from '../../../components/atoms/Button';
import SelectFiles from '../../../components/atoms/SelectFiles';
import { GetBanks } from '../../../redux/actions/GetBank';
import PostCampaignFund from '../../../redux/actions/PostCampaignFund';
import { bca, bni, bersama } from '../../../utils/constants/imagePath';
import formatNumber from '../../../utils/halpers/formatNumber';
import formatRegex from '../../../utils/halpers/formatRegex';
import { GetCampaignDetail } from '../../../redux/actions/GetCampaign';
import { ToastsStore } from 'react-toasts';
import {Helmet} from 'react-helmet';
import formatMail from '../../../utils/halpers/formatMail';
import Swal from 'sweetalert2';


export class Pendanaan extends Component {
    constructor(props){
        super(props);
        this.state = {
            step : 1,
            transfer_file : null,
            loadingSubmitData : false,
            emailNoted : '',
            parameters :{
                funderName : '',
                funderEmail : '',
                funderPhone : '',
                isAnonymous : false,
                fundingAmount : ''
            }
        }
    }

    componentDidMount = () => {
        this.props.GetBank();

        if(!this.props.campaignState.detail){
            this.props.GetCampaignDetail(this.props.match.params.idcamp);
        }
    }

    changeState = (name, value) => { this.setState({ [name] : value }) }

    onChangeHandler = ({name, value}) => {
        switch(name){
            case 'fundingAmount' :
                value = formatRegex(value, 'numberOnly');
                break;
            case 'funderEmail' :
                const cekEmail = formatMail(value);
                (value !== '' && !cekEmail) ? this.changeState('emailNoted', 'Format email tidak sesuai, masukan email yang valid.') :
                this.changeState('emailNoted', '');
                break;
            default :
                break;
        }
        

        this.setState({
            parameters : {
                ...this.state.parameters,
                [name] : (name === 'isAnonymous') ? !this.state.parameters.isAnonymous : value
            }
        })
    }

    actionSelanjutnya = () => {
        const {funderName, funderEmail, funderPhone} = this.state.parameters;
        if(!funderName || !funderEmail || !funderPhone){
            ToastsStore.error('Semua field wajib diisi.');
        }else{
            this.setState({step : 2});
        }
    }

    actionBack = () => {
        if(this.state.step === 1) {
            this.props.history.replace(`/campaign/${this.props.match.params.idcamp}`);
        }else{
            this.setState({ step : 1 })
        }
    }


    actionSubmitData = () => {
        const {transfer_file, parameters} = this.state;
        const {SubmitFund, match} = this.props;
        this.setState({loadingSubmitData : true});
        
        let dataSumbit = {campaign : {}};
        Object.entries(parameters).forEach(data => {
            if(data[0] === 'isAnonymous' || data[1]){
                dataSumbit.campaign[data[0]] = data[1];
            }
        });
        
        let formData = new FormData();
        formData.append('transfer_file', transfer_file);
        formData.append('parameters', JSON.stringify(dataSumbit))

        if(parameters.fundingAmount === '' || parameters.fundingAmount === 0){
            ToastsStore.error('Nominal Donasi wajib diisi.')
            this.setState({loadingSubmitData : false})
        }else if(!transfer_file){
            ToastsStore.error('Bukti transfer wajib dilampirkan.')
            this.setState({loadingSubmitData : false})
        }else{
            SubmitFund(match.params.idcamp, formData, (res) => {
                this.setState({loadingSubmitData : false});
                if(res){
                    Swal.fire({
                      icon: 'success',
                      title: 'Berhasil Mendanai',
                      text: 'Terima Kasih, Pendanaan anda sedang dalam proses pengecekan oleh admin kami.',
                    }).then(() => {
                        window.location.href = `/campaign/${match.params.idcamp}`;
                    })
                }
            })
        }
    }



    stepOne = () => {
        const {parameters, emailNoted} = this.state;
        const col = {label : 'col-12', text : 'col-12 mt-1'};

        return (
            <Fragment>
                <div className='content'>
                    <LabelWithInput col={col} label='Nama Lengkap' required={true}>
                        <TextBox className='mb-3' onChange={e => {this.onChangeHandler(e.target)}} value={parameters.funderName} placeholder='Nama Lengkap' name='funderName' /> 
                    </LabelWithInput>
                    
                    <LabelWithInput col={col} label='Email' required={true}>
                        <TextBox className={`${!emailNoted ? 'mb-3' : ''}`} type='email' onChange={e => {this.onChangeHandler(e.target)}} placeholder='Email' value={parameters.funderEmail} name='funderEmail' /> 
                       {emailNoted && <Label value={emailNoted} type='info' className='d-block mb-3 mt-1 red' />}
                    </LabelWithInput>

                    <LabelWithInput col={col} label='No. Ponsel' required={true}>
                        <TextBox className='mb-3' onChange={e => {this.onChangeHandler(e.target)}} value={parameters.funderPhone} placeholder='No. Ponsel' name='funderPhone' /> 
                    </LabelWithInput>

                    <CheckBox className='mb-3' onChange={e => {this.onChangeHandler(e)}} data={[{text : 'Anonim', value : parameters.isAnonymous, checked : parameters.isAnonymous}]} name='isAnonymous' />

                    <Button width='block' color='blue' title='Selanjutnya' onClick={this.actionSelanjutnya} />
                </div>

                <div className='content'>
                    <h2 className='judul-sub'>Informasi</h2>

                    <ul>
                        <li>Data kontak anda akan kami gunakan untuk memberikan update kepada Anda terkait campaign yang anda dukung.</li>
                        <li>Kolom <b>Nama Lengkap</b>, <b>Email</b>, dan <b>No. Ponsel</b>   Wajib diisi.</li>
                        {/* <li>Sedangkan untuk kolom <b>Email</b> dan <b>No. Ponsel</b> dapat dikosongkan.</li> */}
                    </ul>
                </div>
            </Fragment>
        )
    }

    

    stepTwo = () => {
        const {parameters, transfer_file, loadingSubmitData} = this.state;
        const {bankState, statusState} = this.props;
        const col = {label : 'col-12', text : 'col-12 mt-1'};

        return (
            <Fragment>
                <div className='content'>
                    <h2 className='judul-sub text-center mb-1'>Instruksi Pembayaran</h2>
                    {statusState.loading ? 
                        <div className='text-center'>
                            <div className='spinner spinner-border' />
                        </div> :
                        <ul className='instruksi-pembayaran'>
                            <li>Transfer ke salah satu rekening dibawah ini, dan upload bukti transfer </li>
                            {bankState.list && bankState.list.map((data, index) => {
                                let urlBankImage;
                                switch(data.bankName){
                                    case 'BCA' :
                                        urlBankImage = bca;
                                        break;
                                    case 'BNI' :
                                        urlBankImage = bni;
                                        break;
                                    default : 
                                        urlBankImage = bersama;
                                        break;
                                }
                                return (
                                    <li key={index}>
                                        <div className='d-flex justify-content-start align-items-start'>
                                            <div className='bank-logo'>
                                                <img src={urlBankImage} alt='bank-logo' />
                                            </div>
                                            <div className='text-left'>
                                                <span><b>{data.accountNumber}</b></span>
                                                <span>a/n {data.accountHolderName}</span>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    }
                </div>
                <div className='content'>
                    <LabelWithInput col={col} label='Nominal Pendanaan'>
                        <TextBox className='mb-3' onChange={e => {this.onChangeHandler(e.target)}} value={formatNumber( parameters.fundingAmount, '')} name='fundingAmount' /> 
                    </LabelWithInput>

                    <LabelWithInput col={col} label='Bukti Transfer'>
                        <SelectFiles name='transfer_file' onChange={(name, value) => {this.changeState(name, value)}} files={transfer_file ? 'selected' : ''}  />
                    </LabelWithInput>

                    <Button className='mt-4' loading={loadingSubmitData} width='block' color='blue' title='Kirim' onClick={() => {this.actionSubmitData()}} />
                </div>
            </Fragment>
        )
    }


    render() {
        const {campaignState} = this.props;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Pendanaan - {process.env.REACT_APP_TITLE}</title>
                    <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
                    <meta name="keywords" content={process.env.REACT_APP_KEYWORD} />
                </Helmet>

                <h1 className='judul-page'>Form Pendanaan</h1>
                {
                    !campaignState.detail ? 
                    <div className='bg-loading-campaign'>
                        <div className='spinner spinner-border' />
                    </div> :
                    <div className='pendanaan-wrapper'>
                        <div className='content d-flex justify-content-between align-items'>
                            <Button  onClick={this.actionBack} className='btn-back' width='smallAuto' color='green' title='Kembali' />
                            <p>Anda akan mendanai campaign <b>{campaignState.detail.campaignTitle}</b>, silahkan lengkapi data dibawah ini.</p>
                        </div>

                        {this.state.step === 1 ? this.stepOne() : this.stepTwo()}
                    </div>

                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        bankState : state.bankState,
        statusState : state.statusState,
        campaignState : state.campaignState
    }
}

const mapDispatchToProps = dispatch =>{
	return {
        GetCampaignDetail : (id) => {
            dispatch(GetCampaignDetail(id))
        },
        GetBank : () => {
            dispatch(GetBanks())
        },
        SubmitFund : (id, data, cb) => {
            dispatch(PostCampaignFund(id, data, cb));
        }
	}
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Pendanaan)