import React, { Component } from 'react'
import { logo } from '../../utils/constants/imagePath'
import { Link } from 'react-router-dom';

class KcHeader extends Component {
    closeMenu = () => {
        this.refs.navbarToggle.click();
        // console.log(this.refs.navbarToggle);
    }

    render() {
        return (
            <nav className="header navbar fixed-top navbar-expand-*  transparent-nav">
                <div className='header-wrapper'>
                    <div className='header-top'>
                        <a className="navbar-brand" href="/beranda">
                            <img src={logo} alt='Logo Utama' />
                        </a>

                        <button ref='navbarToggle' className='navbar-toggler navbar-toggler-right' type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="fa fa-bars" />
                        </button>
                    </div>

                    <div className='collapse navbar-collapse' id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li onClick={this.closeMenu} className="nav-item active">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            <li onClick={this.closeMenu} className="nav-item">
                                <Link className="nav-link" to="/campaign">Campaign</Link>
                            </li>
                            {/*<li className="nav-item">
                                <Link className="nav-link" to="/kontributor">Kontributor</Link>
                            </li>*/}
                            <li onClick={this.closeMenu} className="nav-item">
                                <Link className="nav-link" to="/faq">FAQ</Link>
                            </li>
                            <li onClick={this.closeMenu} className="nav-item">
                                <Link className="nav-link" to="/cara-kerja">Cara Kerja</Link>
                            </li>
                            <li onClick={this.closeMenu} className="nav-item">
                                <Link className="nav-link" to="/tentang-kami">Tentang Kami</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default KcHeader;