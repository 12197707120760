import  { SET_BANK_LIST } from '../../utils/constants/actionTypes';

const initialState= {
	list : null,
}

const bankState = (state = initialState, action) =>{
	switch(action.type){
		case SET_BANK_LIST:
			return{
				list : action.payload
			}
		default:
			return state
	}
}


export default bankState;
